import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { fetcher } from "../utils/fetcher";

const StyledLogin = styled.div``;

const Login = ({ login, globalState, setGlobalState, notify }) => {
  const navigate = useNavigate();

  //States
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);

  //Functions
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    login(
      formData,
      (data) => {
        notify.success(`Välkommen ${data.user.username}!`);
      },
      (error) => {
        setLoading(false);
        notify.error(error);
      }
    );
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const verifyToken = async (token) => {
    const { err, data } = await fetcher({
      path: "users-auth/verify-token",
      auth: token,
    });
    if (err) return false;
    else return data;
  };

  const checkUser = async () => {
    const storedToken = localStorage.getItem("hannahdesign-admin-token");
    if (globalState.user) navigate("/", { replace: true });
    else if (storedToken) {
      setGlobalState({
        ...globalState,
        appLoading: true,
      });

      const user = await verifyToken(storedToken);
      if (!user) {
        localStorage.removeItem("hannahdesign-admin-token");
        setGlobalState({
          ...globalState,
          appLoading: false,
        });
        return;
      }
      setGlobalState({
        ...globalState,
        user,
        token: storedToken,
      });
    } else localStorage.removeItem("hannahdesign-admin-token");
  };

  useEffect(() => {
    checkUser();
    //eslint-disable-next-line
  }, [globalState.user]);

  return (
    <StyledLogin>
      <h1>Administratörsinloggning</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={formData.username}
          name="username"
          disabled={loading}
          onChange={handleChange}
          placeholder="Användarnamn"
        />
        <input
          type="password"
          value={formData.password}
          name="password"
          disabled={loading}
          onChange={handleChange}
          placeholder="Lösenord"
        />
        <button type="submit" disabled={loading}>
          {loading ? "Loggar in..." : "Logga in"}
        </button>
      </form>
    </StyledLogin>
  );
};

export default Login;

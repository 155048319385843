import styled from "styled-components";
import { colors } from "../../utils/config";

const StyledDotsDropdown = styled.div`
  width: 20px;
  img {
    width: 100%;
    transform: rotateZ(0deg);
  }
  position: relative;
  cursor: pointer;
  .dropdown-container {
    transition: 100ms ease-in-out;
    position: absolute;
    opacity: 0;
    top: 10px;
    z-index: 10;
    right: 0;
    pointer-events: none;
    padding: 0.5rem;
    padding-bottom: 0.2rem;
    box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    background: ${colors.white};
    button {
      margin: 0;
      width: 100%;
      white-space: nowrap;
      margin-bottom: 0.3rem;
    }
  }
  &:hover {
    .dropdown-container {
      opacity: 1;
      pointer-events: initial;
    }
  }
`;

const DotsDropdown = (props) => {
  const { content, className } = props;
  return (
    <StyledDotsDropdown className={className}>
      <img src="/icons/3-dots.png" alt="" />
      <div className="dropdown-container">{content}</div>
    </StyledDotsDropdown>
  );
};

export default DotsDropdown;

import { useEffect, useState } from "react";
import styled from "styled-components";
import Grid from "../ui/Grid";
import ResponsiveImage from "../ui/ResponsiveImage";

const StyledVariantForm = styled.div`
  color: white;
  form {
    display: flex;
    flex-direction: column;
  }
  .media-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    color: black;
    padding: 1rem;
    &.add {
      cursor: pointer;
    }
  }
`;

const VariantForm = ({ handleSubmit, data, header, images }) => {
  const initialProduct = {
    name: "",
    description: [""],
    priceSEK: "",
    images: [],
    published: true,
  };

  const [formData, setFormData] = useState(initialProduct);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (data) setFormData(data);
  }, [data]);

  return (
    <StyledVariantForm>
      {header && <h3>{header}</h3>}
      <form
        onSubmit={(e) => {
          setLoading(true);
          e.preventDefault();
          handleSubmit(
            formData,
            () => {
              setFormData(initialProduct);
              setLoading(false);
            },
            () => {
              setLoading(false);
            }
          );
        }}
      >
        <input
          type="text"
          value={formData.name}
          onChange={handleChange}
          name="name"
          placeholder="Namn"
          required
          disabled={loading}
        />
        <input
          type="number"
          value={formData.priceSEK}
          onChange={handleChange}
          name="priceSEK"
          placeholder="Pris"
          required
          disabled={loading}
        />
        <label htmlFor="publ">Publicera</label>
        <input
          type="checkbox"
          checked={formData.published}
          onChange={(e) =>
            setFormData({ ...formData, published: e.target.checked })
          }
          id="publ"
        />
        <textarea
          value={formData.description.join("\n")}
          onChange={(e) =>
            setFormData({
              ...formData,
              description: e.target.value.split("\n"),
            })
          }
          name="description"
          placeholder="Beskrivning"
          required
          disabled={loading}
        />
        <h3>Bilder</h3>
        <Grid>
          {formData.images.map((image, i) => (
            <div key={image.uuid}>
              <ResponsiveImage file={image} />
              <button
                onClick={() => {
                  const tempImages = [...formData.images];
                  tempImages.splice(i, 1);
                  setFormData({
                    ...formData,
                    images: tempImages,
                  });
                }}
              >
                Ta bort
              </button>
            </div>
          ))}
        </Grid>
        <button type="submit" disabled={loading}>
          {loading ? "Sparar..." : "Spara"}
        </button>
      </form>
      <h3>Lägg till bilder</h3>
      <Grid>
        {images.map((image) => (
          <div
            key={`add${image.uuid}`}
            onClick={() => {
              const tempImages = [...formData.images];
              tempImages.push(image);
              setFormData({
                ...formData,
                images: tempImages,
              });
            }}
          >
            <ResponsiveImage file={image} />
          </div>
        ))}
      </Grid>
    </StyledVariantForm>
  );
};

export default VariantForm;

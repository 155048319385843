import styled from "styled-components";
import { colors } from "../../utils/config";
import DotsDropdown from "../ui/DotsDropdown";
import ResponsiveImage from "../ui/ResponsiveImage";

const StyledVariantCard = styled.div`
  background: ${colors.white};
  border-radius: 8px;
  .image-container {
    max-height: 200px;
    overflow: hidden;
    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }
  }
  .text-container {
    position: relative;
    padding: 1rem;
    padding-right: 2rem;
    .family-card-dropdown {
      position: absolute;
      top: 0;
      right: 5px;
      z-index: 20;
    }
  }
`;

const VariantCard = ({ variant, actions }) => {
  return (
    <StyledVariantCard>
      <div className="image-container">
        <ResponsiveImage file={variant?.images?.[0]} />
      </div>
      <div className="text-container">
        <p className="m0">{variant.name}</p>
        {actions && (
          <DotsDropdown
            className="family-card-dropdown"
            content={
              <>
                <button onClick={() => actions.deleteVariant(variant)}>
                  Radera
                </button>
                <button onClick={() => actions.editVariant(variant)}>
                  Ändra
                </button>
              </>
            }
          />
        )}
      </div>
    </StyledVariantCard>
  );
};

export default VariantCard;

import styled from "styled-components";

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: ${({ cols }) => cols || "1fr 1fr 1fr"};
  gap: ${({ gap }) => gap || "1rem"};
`;

const Grid = ({ cols, gap, children }) => {
  return (
    <StyledGrid cols={cols} gap={gap}>
      {children}
    </StyledGrid>
  );
};

export default Grid;

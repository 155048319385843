import styled from "styled-components";
import { humanFileSize } from "../../utils/humanFileSize";
import MediaThumbnail from "../ui/MediaThumbnail";

const StyledMediaItem = styled.div`
  background: lightgray;
  margin-bottom: 0.5rem;
  display: grid;
  grid-template-columns: 1fr 4fr 1fr 1fr 1fr 1fr;
  gap: 1rem;
  img {
    cursor: pointer;
  }
`;

const MediaItem = ({ file, deleteFile, updateFile, spotlight }) => {
  return (
    <StyledMediaItem>
      <MediaThumbnail file={file} onClick={spotlight} />
      <p className="m0">{file.name}</p>
      <p className="m0">{humanFileSize(file.size)}</p>
      <p className="m0">{file.type}</p>
      <button onClick={() => deleteFile(file)}>Radera</button>
      <button onClick={() => updateFile(file)}>Ändra</button>
    </StyledMediaItem>
  );
};

export default MediaItem;

export const server =
  process.env.NODE_ENV === "production"
    ? "https://backend.parl.se/.netlify/functions/server/"
    : "http://localhost:9999/.netlify/functions/server/";

// export const DOServer =
//   process.env.NODE_ENV === "production"
//     ? "https://api.parl.se/api/"
//     : "http://localhost:4001/api/";

export const DOServer = "https://api.parl.se/api/";

export const colors = {
  white: "#ffffff",
  black: "#000000",
  bgBeige: "#EBEBD3",
  sandyBrown: "#EE964B",
  darkBlue: "#083D77",
  azure: "#007FFF",
  orangeSoda: "#F95738",
  lightBlue: "#00acc3",
  pinkLavender: "#E0ACD5",
  darkPastelGreen: "#20BF55",
  main: "#E496C3",
  offWhite: "#e4e4e4",
};

export const measurments = {
  contentWidth: 1000,
  navHeight: 80,
};

import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { fetcher } from "../../utils/fetcher";
import FullscreenLoader from "../loaders/FullScreenLoader";
import Navigation from "../ui/Navigation";

const ProtectedRoute = ({
  globalState,
  setGlobalState,
  notify,
  modal,
  closeModal,
}) => {
  const { appLoading: loading } = globalState;
  const navigate = useNavigate();

  //Functions
  const checkUser = () => {
    const storedToken = localStorage.getItem("bg-kurswebb-admin-token");
    const { token, user } = globalState;
    if (token && user) {
      handleAuthenticated();
    } else if (storedToken) {
      handlePreviouslyAuthenticated();
    } else {
      handleUnauthenticated();
    }
  };

  const verifyToken = async (token) => {
    const { err, data } = await fetcher({
      path: "admin-auth/verify-token",
      auth: token,
    });
    if (err) return false;
    else return data;
  };

  const handleAuthenticated = () => {
    finishLoading();
  };

  const handlePreviouslyAuthenticated = async () => {
    const storedToken = localStorage.getItem("bg-kurswebb-admin-token");
    const user = await verifyToken(storedToken);
    if (!user) return handleUnauthenticated();
    setGlobalState({
      ...globalState,
      token: storedToken,
      user,
    });
  };

  const handleUnauthenticated = () => {
    navigate("/login", { replace: true });
    finishLoading();
  };

  const finishLoading = () => {
    setGlobalState({
      ...globalState,
      appLoading: false,
    });
  };

  //Effects
  useEffect(() => {
    checkUser();
    //eslint-disable-next-line
  }, [globalState.user]);

  if (loading) return <FullscreenLoader />;
  else
    return (
      <>
        <Navigation
          globalState={globalState}
          setGlobalState={setGlobalState}
          notify={notify}
        />
        <Outlet
          context={{ globalState, setGlobalState, notify, modal, closeModal }}
        />
      </>
    );
};

export default ProtectedRoute;

import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import styled from "styled-components";
import Grid from "../../components/ui/Grid";
import ResponsiveImage from "../../components/ui/ResponsiveImage";
import { DOServer } from "../../utils/config";
import { fetcher } from "../../utils/fetcher";

const StyledBeads = styled.div`
  img {
    width: 100%;
    max-width: 500px;
  }
  .single-bead {
    display: grid;
    align-items: center;
    grid-template-columns: 50px auto 60px 60px;
    padding: 1rem;
    gap: 1rem;
  }
`;

const Beads = () => {
  const { globalState } = useOutletContext();

  const initialBead = {
    name: "",
    image: null,
  };

  const [formData, setFormData] = useState(initialBead);
  const [beads, setBeads] = useState([]);
  const [images, setImages] = useState([]);

  const getBeads = async () => {
    const { err, data } = await fetcher({
      path: "beads",
      auth: globalState.token,
    });
    if (err) return;
    setBeads(data);
  };

  const getImages = async () => {
    const { err, data } = await fetcher({
      path: "files-admin",
      server: DOServer,
      auth: globalState.token,
    });
    if (err) return;
    setImages(data);
  };

  const addBead = async (e) => {
    e.preventDefault();
    const { err } = await fetcher({
      path: `beads`,
      body: formData,
      method: "POST",
      auth: globalState.token,
    });
    if (err) return;
    setFormData(initialBead);
    getBeads();
  };

  const updateBead = async (e) => {
    e.preventDefault();
    const { err } = await fetcher({
      path: `beads/${formData._id}`,
      body: formData,
      method: "POST",
      auth: globalState.token,
    });
    if (err) return;
    setFormData(initialBead);
    getBeads();
  };

  const deleteBead = async (bead) => {
    const { err } = await fetcher({
      path: `beads/${bead._id}`,
      method: "DELETE",
      auth: globalState.token,
    });
    if (err) return;
    getBeads();
  };

  useEffect(() => {
    getBeads();
    getImages();
    //eslint-disable-next-line
  }, []);

  return (
    <StyledBeads>
      {beads.map((bead) => (
        <div key={bead._id}>
          <div className="single-bead">
            <ResponsiveImage file={bead.image} />
            <p className="m0">{bead.name}</p>
            <button onClick={() => deleteBead(bead)}>Delete</button>
            <button onClick={() => setFormData(bead)}>Edit</button>
          </div>
          <hr />
        </div>
      ))}
      <hr />
      <br />
      <br />
      <br />
      <form onSubmit={formData._id ? updateBead : addBead}>
        <input
          type="text"
          value={formData.name}
          onChange={(e) => {
            setFormData({
              ...formData,
              name: e.target.value,
            });
          }}
        />
        <ResponsiveImage file={formData.image} />
        <button type="submit">
          {formData._id ? "Uppdatera" : "Lägg till"}
        </button>
        <Grid cols={"repeat(6, 1fr)"}>
          {images.map((image) => (
            <div
              className="image"
              key={image.uuid}
              onClick={() =>
                setFormData({
                  ...formData,
                  image,
                })
              }
            >
              <ResponsiveImage file={image} />
            </div>
          ))}
        </Grid>
      </form>
    </StyledBeads>
  );
};

export default Beads;

import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import styled from "styled-components";
import FamilyCard from "../../components/cards/FamilyCard";
import FamilyForm from "../../components/forms/FamilyForm";
import Grid from "../../components/ui/Grid";
import { fetcher } from "../../utils/fetcher";

const StyledDashboard = styled.div`
  padding-top: 2rem;
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    align-items: flex-start;
  }
`;

const Dashboard = () => {
  const { globalState, notify, modal, closeModal } = useOutletContext();

  const [families, setFamilies] = useState();

  const getFamilies = async () => {
    const { err, data } = await fetcher({
      path: "families",
      auth: globalState.token,
    });
    if (err) {
      notify.error("Kunde inte hämta produktfamiljer");
      return;
    }
    setFamilies(data);
  };

  const createFamily = async (formData, cb, errCb) => {
    const body = { ...formData };
    // body.products.map((product) => product._id);
    const { err } = await fetcher({
      path: "families",
      method: "POST",
      body,
      auth: globalState.token,
    });
    if (err) {
      notify.error("Kunde inte spara produktfamilj");
      errCb();
      return;
    }
    closeModal();
    cb();
    notify.success("Produkten lades till");
    getFamilies();
  };

  const createFamilyInit = () => {
    modal(
      <FamilyForm
        handleSubmit={createFamily}
        header={"Lägg till produktfamilj"}
      />
    );
  };

  // const deleteProduct = async (product, cb, errCb) => {
  //   const { err } = await fetcher({
  //     path: `products/${product._id}`,
  //     method: "DELETE",
  //     auth: globalState.token,
  //   });
  //   if (err) {
  //     notify.error("Kunde inte radera produkten");
  //     errCb();
  //     return;
  //   }
  //   notify.success("Produkten raderades");
  //   cb();
  //   closeModal();
  //   getProducts();
  // };

  // const deletProductInit = (product) => {
  //   modal(
  //     <DeleteConfirmation
  //       action={deleteProduct}
  //       item={product}
  //       handleInvalidKey={() => notify.error("Skriv in rätt värde i fältet")}
  //       deleteKey={"name"}
  //       name={"produkten"}
  //     />
  //   );
  // };

  // const editProduct = async (product, cb, errCb) => {
  //   const { err } = await fetcher({
  //     path: `products/${product._id}`,
  //     method: "POST",
  //     auth: globalState.token,
  //     body: product,
  //   });
  //   if (err) {
  //     notify.error("Kunde inte uppdatera produkt");
  //     errCb();
  //     return;
  //   }
  //   closeModal();
  //   cb();
  //   getProducts();
  //   notify.success("Produkten uppdaterades");
  // };

  // const editProductInit = (product) => {
  //   modal(
  //     <ProductForm
  //       images={images}
  //       header="Ändra produkt"
  //       handleSubmit={editProduct}
  //       data={product}
  //     />
  //   );
  // };

  //

  useEffect(() => {
    getFamilies();
    //eslint-disable-next-line
  }, []);

  return (
    <StyledDashboard className="content">
      {families && (
        <div>
          <div className="header mtop2">
            <h1 className="m0">Produktfamiljer</h1>
            <button onClick={createFamilyInit}>Lägg till familj</button>
          </div>
          <Grid>
            {families.map((family) => (
              <FamilyCard key={family._id} family={family} />
            ))}
          </Grid>
          <br />
          <br />
          <br />
        </div>
      )}
    </StyledDashboard>
  );
};

export default Dashboard;

import { Link } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../utils/config";
import ResponsiveImage from "../ui/ResponsiveImage";

const StyledProductCard = styled.div`
  background: ${colors.white};
  border-radius: 8px;
  .image-container {
    max-height: 200px;
    overflow: hidden;
    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }
  }
  .text-container {
    position: relative;
    padding: 1rem;
    padding-right: 2rem;
    .product-card-dropdown {
      position: absolute;
      top: 0;
      right: 5px;
      z-index: 20;
    }
  }
`;

const ProductCard = ({ product, family }) => {
  return (
    <Link to={`/product/${family._id}/${product._id}`}>
      <StyledProductCard>
        <div className="image-container">
          <ResponsiveImage file={product.images?.[0]} />
        </div>
        <div className="text-container">
          <p className="m0">{product.name}</p>
        </div>
      </StyledProductCard>
    </Link>
  );
};

export default ProductCard;

import styled from "styled-components";
import { ToastContainer } from "react-toastify";
import Modal from "../ui/Modal";

const StyledLayout = styled.div``;

const Layout = ({ children, modal, closeModal }) => {
  return (
    <StyledLayout>
      {children}
      <Modal modal={modal} closeModal={closeModal} />
      <ToastContainer newestOnTop />
    </StyledLayout>
  );
};

export default Layout;

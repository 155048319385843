import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import styled from "styled-components";
import OrderCard from "../../components/cards/OrderCard";
import { fetcher } from "../../utils/fetcher";

const StyledOrders = styled.div`
  padding-top: 1rem;
  padding-bottom: 12rem;
`;

const Orders = () => {
  const { globalState, notify } = useOutletContext();

  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  const getOrders = async () => {
    setLoading(true);
    const { err, data } = await fetcher({
      path: "admin-orders",
      auth: globalState.token,
    });
    if (err) {
      setLoading(false);
      notify.error("Kunder inte hämta ordrar");
      return;
    }
    setOrders(data.reverse());
    setLoading(false);
  };

  const markAsSent = async (order) => {
    setLoading(true);
    const { err } = await fetcher({
      path: `orders/mark-order-as-shipped/${order.paysonId}`,
      auth: globalState.token,
    });
    if (err) {
      setLoading(false);
      getOrders();
      notify.error("Något gick fel!");
      return;
    }

    notify.success("Status uppdaterat");
    getOrders();
  };

  const markAsFinished = async (order) => {
    setLoading(true);
    const { err } = await fetcher({
      path: `orders/mark-order-as-finished/${order.paysonId}`,
      auth: globalState.token,
    });
    if (err) {
      setLoading(false);
      getOrders();
      notify.error("Något gick fel!");
      return;
    }
    notify.success("Status uppdaterat");
    getOrders();
  };

  useEffect(() => {
    getOrders();
    //eslint-disable-next-line
  }, []);

  if (loading) return <p>Laddar...</p>;
  return (
    <StyledOrders className="content">
      {orders.map((order) => (
        <OrderCard
          key={order._id}
          order={order}
          markAsSent={markAsSent}
          markAsFinished={markAsFinished}
        />
      ))}
    </StyledOrders>
  );
};

export default Orders;

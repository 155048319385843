import styled from "styled-components";
import { DOServer } from "../../utils/config";

const StyledResponsiveImage = styled.img`
  width: 100%;
  display: block;
`;

const ResponsiveImage = ({ file }) => {
  if (!file) return null;
  if (file.type !== "image") return null;
  const imageDOSERVER = DOServer.replace("api/", "");
  return (
    <StyledResponsiveImage
      src={`${imageDOSERVER}${file.uuid}/2000.${file.extension}`}
      srcSet={`${imageDOSERVER}${file.uuid}/100.${file.extension} 100w,
      ${imageDOSERVER}${file.uuid}/500.${file.extension} 500w,
      ${imageDOSERVER}${file.uuid}/1000.${file.extension} 1000w,
      ${imageDOSERVER}${file.uuid}/2000.${file.extension} 2000w`}
      alt={file.name}
    />
  );
};

export default ResponsiveImage;

import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import styled from "styled-components";
import { colors, DOServer } from "../../utils/config";
import { fetcher } from "../../utils/fetcher";
import axios from "axios";
import { v4 as uuid } from "uuid";
import MediaItem from "../../components/cards/MediaItem";
import FileForm from "../../components/forms/FileForm";
import ResponsiveImage from "../../components/ui/ResponsiveImage";
import Plus from "../../components/ui/Plus";
import Video from "../../components/ui/Video";

const StyledMedia = styled.div`
  #fileInput {
    display: none;
  }
  .spotlight {
    position: fixed;
    inset: 0;
    width: 100vw;
    height: 100vh;
    z-index: 20;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 2rem;
    &-close {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      cursor: pointer;
    }
    img {
      width: 100%;
      height: 100%;
      max-height: 100vh;
      max-width: 100vw;
      object-fit: contain;
    }
  }
`;

const Media = () => {
  //Global
  const { globalState, notify, modal, closeModal } = useOutletContext();

  //States
  const [files, setFiles] = useState();
  const [uploading, setUploading] = useState(false);
  const [spotlight, setSpotlight] = useState();

  //Functions
  const getFiles = async () => {
    const { err, data } = await fetcher({
      path: "files-admin",
      server: DOServer,
      auth: globalState.token,
    });
    if (err) {
      notify.error("Kunde inte hämta filer");
      return;
    }
    setFiles(data);
  };

  const uploadButtonPress = () => {
    const fileInput = document.getElementById("fileInput");
    fileInput.click();
  };

  const handleUpload = (e) => {
    const files = e.target.files;
    if (!files?.length) return;
    setUploading(true);
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append(uuid(), files[i]);
    }
    axios
      .post(`${DOServer}files-admin`, formData, {
        headers: {
          Authorization: "Bearer " + globalState.token,
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        notify.success("Filerna laddades upp");
        setUploading(false);
        getFiles();
        e.target.value = "";
      })
      .catch(() => {
        setUploading(false);
        e.target.value = "";
        notify.error("Kunde inte ladda upp filer");
      });
  };

  const deleteFileInit = (file) => {
    deleteFile(
      file,
      () => {},
      () => {}
    );
    // modal(
    //   <DeleteConfirmation
    //     item={file}
    //     deleteKey={"name"}
    //     handleInvalidKey={() => notify.error("Skriv in rätt värde i fältet")}
    //     action={deleteFile}
    //     name="filen"
    //   />
    // );
  };

  const deleteFile = async (file, cb, errCb) => {
    const { err } = await fetcher({
      path: `files-admin/${file.uuid}`,
      method: "DELETE",
      server: DOServer,
      auth: globalState.token,
    });
    if (err) {
      notify.error("Kunde inte radera filen");
      errCb();
      return;
    }
    notify.success("Filen raderades");
    cb();
    closeModal();
    getFiles();
  };

  const updateFileInit = (file) => {
    modal(
      <FileForm header="Ändra fil" data={file} handleSubmit={updateFile} />
    );
  };

  const updateFile = async (data, cb, errCb) => {
    const { err } = await fetcher({
      path: `files-admin/${data.uuid}`,
      server: DOServer,
      method: "POST",
      body: { name: data.name },
      auth: globalState.token,
    });
    if (err) {
      errCb();
      notify.error("Kunde inte uppdatera fil");
      return;
    }
    cb();
    notify.success("Filen uppdaterades");
    closeModal();
    getFiles();
  };

  //Effects
  useEffect(() => {
    getFiles();
    //eslint-disable-next-line
  }, []);

  return (
    <StyledMedia>
      <h1>Media</h1>
      <button onClick={uploadButtonPress}>Ladda upp filer</button>
      {uploading && <p>Laddar upp filer, lämna inte sidan</p>}
      <input
        type="file"
        id="fileInput"
        // accept="image/*,video/*"
        multiple
        onChange={handleUpload}
      />
      <hr />
      {files &&
        files.map((file) => (
          <MediaItem
            file={file}
            key={file.uuid}
            spotlight={() => {
              setSpotlight(file);
            }}
            deleteFile={deleteFileInit}
            updateFile={updateFileInit}
          />
        ))}
      {spotlight && (
        <div
          className="spotlight"
          onClick={() => {
            setSpotlight(null);
          }}
        >
          <div className="spotlight-close">
            <Plus cross color={colors.white} />
          </div>
          {spotlight.type === "image" && <ResponsiveImage file={spotlight} />}
          {spotlight.type === "video" && <Video file={spotlight} />}
        </div>
      )}
    </StyledMedia>
  );
};

export default Media;

import styled from "styled-components";
import { DOServer } from "../../utils/config";

const StyledVideo = styled.video`
  width: 100%;
  display: block;
`;

const Video = ({ file }) => {
  if (!file) return null;
  if (file.type !== "video") return null;
  return <StyledVideo src={`${DOServer}${file.uuid}/main.mp4`} controls />;
};

export default Video;

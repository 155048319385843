import { useEffect, useState } from "react";
import styled from "styled-components";

const StyledProductForm = styled.div`
  color: white;
  form {
    display: flex;
    flex-direction: column;
  }
  .media-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    color: black;
    padding: 1rem;
    &.add {
      cursor: pointer;
    }
  }
`;

const ProductForm = ({ handleSubmit, data, header }) => {
  const initialProduct = {
    name: "",
    description: [""],
  };

  const [formData, setFormData] = useState(initialProduct);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (data) setFormData(data);
  }, [data]);

  return (
    <StyledProductForm>
      {header && <h3>{header}</h3>}
      <form
        onSubmit={(e) => {
          setLoading(true);
          e.preventDefault();
          handleSubmit(
            formData,
            () => {
              setFormData(initialProduct);
              setLoading(false);
            },
            () => {
              setLoading(false);
            }
          );
        }}
      >
        <input
          type="text"
          value={formData.name}
          onChange={handleChange}
          name="name"
          placeholder="Produktnamn"
          required
          disabled={loading}
        />
        <textarea
          value={formData.description.join("\n")}
          onChange={(e) =>
            setFormData({
              ...formData,
              description: e.target.value.split("\n"),
            })
          }
          name="description"
          placeholder="Beskrivning"
          required
          disabled={loading}
        />

        <button type="submit" disabled={loading}>
          {loading ? "Sparar..." : "Spara"}
        </button>
      </form>
    </StyledProductForm>
  );
};

export default ProductForm;

import { useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import styled from "styled-components";
import VariantCard from "../../components/cards/VariantCard";
import DeleteConfirmation from "../../components/forms/DeleteConfirmation";
import VariantForm from "../../components/forms/VariantForm";
import Grid from "../../components/ui/Grid";
import { DOServer } from "../../utils/config";
import { fetcher } from "../../utils/fetcher";

const StyledProduct = styled.div``;

const Product = () => {
  const { id, familyid: familyId } = useParams();
  const { globalState, modal, notify } = useOutletContext();

  const [product, setProduct] = useState();
  const [images, setImages] = useState([]);

  const getProduct = async () => {
    const { err, data } = await fetcher({
      path: `products/${id}`,
      auth: globalState.token,
    });
    if (err) return;
    setProduct(data);
  };

  const getImages = async () => {
    const { err, data } = await fetcher({
      path: `files-admin`,
      server: DOServer,
      auth: globalState.token,
    });
    if (err) return;
    setImages(data);
  };

  const createVariant = async (formData, cb, errCb) => {
    const { err } = await fetcher({
      path: `variants/?productid=${id}`,
      method: "POST",
      body: formData,
      auth: globalState.token,
    });
    if (err) {
      errCb();
      return;
    }
    cb();
    getProduct();
  };

  const createVariantInit = () => {
    modal(
      <VariantForm
        images={images}
        handleSubmit={createVariant}
        header="Ny variant"
      />
    );
  };

  const deleteVariant = async (variant, cb, errCb) => {
    const { err } = fetcher({
      path: `variants/${variant._id}`,
      method: "DELETE",
      auth: globalState.token,
    });
    if (err) {
      errCb();
      return;
    }
    cb();
    getProduct();
  };

  const deleteProductInit = (product) => {
    modal(
      <DeleteConfirmation
        action={deleteProduct}
        item={product}
        handleInvalidKey={() => notify.error("Skriv in rätt värde i fältet")}
        deleteKey={"name"}
        name={"produkten"}
      />
    );
  };

  const deleteProduct = async (product, cb, errCb) => {
    const { err } = await fetcher({
      path: `products/${product._id}`,
      method: "DELETE",
      auth: globalState.token,
    });
    if (err) {
      errCb();
      return;
    }
    cb(window.location.reload());
  };

  const deleteVariantInit = (variant) => {
    modal(
      <DeleteConfirmation
        action={deleteVariant}
        item={variant}
        handleInvalidKey={() => notify.error("Skriv in rätt värde i fältet")}
        deleteKey={"name"}
        name={"varianten"}
      />
    );
  };

  const editVariant = async (formData, cb, errCb) => {
    const { err } = await fetcher({
      path: `variants/${formData._id}`,
      method: "POST",
      body: formData,
      auth: globalState.token,
    });
    if (err) {
      errCb();
      return;
    }
    cb();
    getProduct();
  };

  const editVariantInit = (variant) => {
    modal(
      <VariantForm
        data={variant}
        handleSubmit={editVariant}
        header="Redigera variant"
        images={images}
      />
    );
  };

  useEffect(() => {
    getProduct();
    getImages();
    //eslint-disable-next-line
  }, [id, familyId]);

  if (!product) return null;
  return (
    <StyledProduct className="content">
      <h1>Produkt {product.name}</h1>
      <button onClick={createVariantInit}>Lägg till variant</button>
      <button onClick={() => deleteProductInit(product)}>
        Radera {product.name}
      </button>
      <Grid>
        {product.variants.map((variant) => (
          <VariantCard
            variant={variant}
            key={variant._id}
            actions={{
              deleteVariant: deleteVariantInit,
              editVariant: editVariantInit,
            }}
          />
        ))}
      </Grid>
      <br />
      <br />
      <br />
    </StyledProduct>
  );
};

export default Product;

import { useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import styled from "styled-components";
import ProductCard from "../../components/cards/ProductCard";
import DeleteConfirmation from "../../components/forms/DeleteConfirmation";
import FamilyForm from "../../components/forms/FamilyForm";
import ProductForm from "../../components/forms/ProductForm";
import Grid from "../../components/ui/Grid";
import { fetcher } from "../../utils/fetcher";

const StyledFamily = styled.div``;

const Family = () => {
  const { id } = useParams();
  const { globalState, modal, notify, closeModal } = useOutletContext();

  const [family, setFamily] = useState();

  const getFamily = async () => {
    const { err, data } = await fetcher({
      path: `families/${id}`,
      auth: globalState.token,
    });
    if (err) return;
    setFamily(data);
  };

  const deleteFamily = async (product, cb, errCb) => {
    const { err } = await fetcher({
      path: `families/${product._id}`,
      method: "DELETE",
      auth: globalState.token,
    });
    if (err) {
      notify.error("Kunde inte radera produktfamiljen");
      errCb();
      return;
    }
    notify.success("Produktfamiljen raderades");
    cb();
    closeModal();
    getFamily();
  };

  const deletFamilyInit = () => {
    modal(
      <DeleteConfirmation
        action={deleteFamily}
        item={family}
        handleInvalidKey={() => notify.error("Skriv in rätt värde i fältet")}
        deleteKey={"name"}
        name={"produktfamiljen"}
      />
    );
  };

  const editFamily = async (family, cb, errCb) => {
    const body = { ...family };
    body.products.map((product) => product._id);
    const { err } = await fetcher({
      path: `families/${family._id}`,
      method: "POST",
      auth: globalState.token,
      body,
    });
    if (err) {
      notify.error("Kunde inte uppdatera produktfamilj");
      errCb();
      return;
    }
    closeModal();
    cb();
    getFamily();
    notify.success("Produktfamiljen uppdaterades");
  };

  const editFamilyInit = () => {
    modal(
      <FamilyForm
        header="Ändra produktfamilj"
        handleSubmit={editFamily}
        data={family}
      />
    );
  };

  const createProduct = async (formData, cb, errCb) => {
    const { err } = await fetcher({
      path: `products/?familyid=${family._id}`,
      method: "POST",
      body: formData,
      auth: globalState.token,
    });
    if (err) {
      notify.error("Kunde inte spara produkt");
      errCb();
      return;
    }
    closeModal();
    cb();
    notify.success("Produkten lades till");
    getFamily();
  };

  const createProductInit = () => {
    modal(
      <ProductForm handleSubmit={createProduct} header={"Lägg till produkt"} />
    );
  };

  useEffect(() => {
    getFamily();
    //eslint-disable-next-line
  }, [id]);

  if (!family) return null;

  return (
    <StyledFamily className="content">
      <h1>Produktfamilj {family.name}</h1>
      <button onClick={editFamilyInit}>Redigera</button>
      <button onClick={deletFamilyInit}>Radera</button>
      <button onClick={createProductInit}>Lägg till produkt</button>
      <Grid>
        {family.products.map((product) => (
          <ProductCard product={product} key={product._id} family={family} />
        ))}
      </Grid>
    </StyledFamily>
  );
};

export default Family;

import styled from "styled-components";
import { useOutletContext } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetcher } from "../../utils/fetcher";
import { DOServer } from "../../utils/config";
import ResponsiveImage from "../../components/ui/ResponsiveImage";
import Grid from "../../components/ui/Grid";

const StyledBinaryImages = styled.div``;

const BinaryImages = () => {
  const { globalState } = useOutletContext();

  const [binaryImages, setBinaryImages] = useState([]);
  const [images, setImages] = useState([]);

  const getBinaryImages = async () => {
    const { err, data } = await fetcher({
      path: "binary-images",
      auth: globalState.token,
    });
    if (err) return;
    setBinaryImages(data);
  };

  const getImages = async () => {
    const { err, data } = await fetcher({
      path: "files-admin",
      server: DOServer,
      auth: globalState.token,
    });
    if (err) return;
    setImages(data);
  };

  const createBinaryImage = async (image) => {
    const { err } = await fetcher({
      path: "binary-images",
      method: "POST",
      body: { image },
      auth: globalState.token,
    });
    if (err) return;
    getBinaryImages();
  };

  const deleteBinaryImage = async (id) => {
    const { err } = await fetcher({
      method: "DELETE",
      path: `binary-images/${id}`,
      auth: globalState.token,
    });
    if (err) return;
    getBinaryImages();
  };

  useEffect(() => {
    getImages();
    getBinaryImages();
    //eslint-disable-next-line
  }, []);

  return (
    <StyledBinaryImages>
      <h1>Binärbilder</h1>
      <h2>Befintliga</h2>
      <Grid cols="1fr 1fr 1fr 1fr 1fr">
        {binaryImages.map((bImage) => (
          <BinaryImage
            binaryImage={bImage}
            deleteBinaryImage={deleteBinaryImage}
            key={bImage._id}
          />
        ))}
      </Grid>
      <hr />
      <br />
      <br />
      <br />
      <h2>Lägg till</h2>
      <Grid cols="1fr 1fr 1fr 1fr 1fr">
        {images.map((image) => (
          <div key={image.uuid}>
            <ResponsiveImage file={image} />
            <button onClick={() => createBinaryImage(image)}>Lägg till</button>
          </div>
        ))}
      </Grid>
    </StyledBinaryImages>
  );
};

export default BinaryImages;

const StyledBinaryImage = styled.div``;

const BinaryImage = ({ binaryImage, deleteBinaryImage }) => {
  return (
    <StyledBinaryImage>
      <ResponsiveImage file={binaryImage.image} />
      <button onClick={() => deleteBinaryImage(binaryImage._id)}>Delete</button>
    </StyledBinaryImage>
  );
};

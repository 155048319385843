import { useState } from "react";
import styled from "styled-components";

const StyledDeleteConfirmation = styled.div`
  color: white;
`;

const DeleteConfirmation = ({
  item,
  deleteKey,
  handleInvalidKey,
  action,
  name,
}) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");

  return (
    <StyledDeleteConfirmation>
      <h3 className="m0">
        Är du säker på att du vill <strong>permanent</strong> radera {name}?
      </h3>
      <p className="m0">Åtgärden går inte att ångra.</p>
      <input
        value={value}
        onChange={(e) => setValue(e.target.value)}
        disabled={loading}
      />
      <p className="m0">
        Skriv in <code>{item[deleteKey]}</code> i fältet för att bekräfta.
      </p>
      <button
        onClick={() => {
          if (value !== item[deleteKey]) {
            handleInvalidKey();
            return;
          }
          setLoading(true);
          action(
            item,
            () => {
              setLoading(false);
            },
            () => {
              setLoading(false);
            }
          );
        }}
        disabled={value !== item[deleteKey] || loading}
      >
        {loading ? "Raderar..." : "Radera permament"}
      </button>
    </StyledDeleteConfirmation>
  );
};

export default DeleteConfirmation;

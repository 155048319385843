import styled from "styled-components";
import { colors } from "../../utils/config";
import Loader from "./Loader";

const StyledFullScreenLoader = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FullScreenLoader = () => {
  return (
    <StyledFullScreenLoader>
      <Loader color={colors.black} ellipsis />
    </StyledFullScreenLoader>
  );
};

export default FullScreenLoader;

import { useEffect, useRef } from "react";
import styled from "styled-components";
import { colors } from "../../utils/config";

const StyledModal = styled.div`
  position: fixed;
  inset: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  display: flex;
  justify-content: flex-end;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
  pointer-events: none;
  transition: 200ms ease-in-out;
  .modal-inner {
    padding: 2rem;
    background: ${colors.black};
    height: 100%;
    min-height: 100vh;
    overflow-y: scroll;
    width: 50vw;
    max-width: 800px;
    position: relative;
    .modal-close {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  &.active {
    opacity: 1;
    pointer-events: initial;
  }
`;

const Modal = ({ modal, closeModal }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        closeModal();
      }
    };
    if (modal.active) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    //eslint-disable-next-line
  }, [modalRef, modal.active]);

  return (
    <StyledModal className={modal.active ? "active" : " "}>
      <div className="modal-inner" ref={modalRef}>
        <button className="modal-close" onClick={closeModal}>
          Stäng
        </button>
        {modal.content}
      </div>
    </StyledModal>
  );
};

export default Modal;

import React, { useState } from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import Layout from "./components/wrappers/Layout";
import ProtectedRoute from "./components/wrappers/ProtectedRoute";
import Login from "./routes/Login";
import Dashboard from "./routes/protected/Dashboard";
import Register from "./routes/Register";
import { fetcher } from "./utils/fetcher";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./styles/main.css";
import "./styles/typo.css";
import "./styles/helpers.css";
import Media from "./routes/protected/Media";
import styled from "styled-components";
import { colors, measurments } from "./utils/config";
import Orders from "./routes/protected/Orders";
import Family from "./routes/protected/Family";
import Product from "./routes/protected/Product";
import Beads from "./routes/protected/Beads";
import BinaryImages from "./routes/protected/BinaryImages";
import Ekonomi from "./routes/protected/Ekonomi";

const StyledApp = styled.div`
  background: ${colors.offWhite};
  .content {
    max-width: ${measurments.contentWidth}px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

const App = () => {
  const [globalState, setGlobalState] = useState({
    user: null,
    token: null,
    appLoading: true,
    modal: {
      active: false,
      content: <div />,
    },
  });

  const toastOptions = {
    position: "top-right",
    autoClose: true,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
  };

  const notify = {
    info: (m) => toast.info(m, toastOptions),
    error: (m) => toast.error(m, toastOptions),
    success: (m) => toast.success(m, toastOptions),
  };

  const login = async (formData, cb, errCb) => {
    const { err, data } = await fetcher({
      path: "users-no-auth/login",
      method: "POST",
      body: formData,
    });
    if (err) return errCb("Fel e-post eller lösenord");
    setGlobalState({
      ...globalState,
      token: data.token,
      user: data.user,
    });
    localStorage.setItem("hannahdesign-admin-token", data.token);
    cb(data);
  };

  const modal = (content) => {
    setGlobalState({
      ...globalState,
      modal: {
        active: true,
        content,
      },
    });
  };

  const closeModal = () => {
    setGlobalState({
      ...globalState,
      modal: {
        ...globalState.modal,
        active: false,
      },
    });
  };

  return (
    <StyledApp>
      <BrowserRouter>
        <Layout modal={globalState.modal} closeModal={closeModal}>
          <Routes>
            <Route
              path="login"
              element={
                <Login
                  login={login}
                  globalState={globalState}
                  setGlobalState={setGlobalState}
                  notify={notify}
                />
              }
            />
            <Route
              path="register"
              element={
                <Register
                  login={login}
                  globalState={globalState}
                  setGlobalState={setGlobalState}
                  notify={notify}
                />
              }
            />
            <Route
              element={
                <ProtectedRoute
                  globalState={globalState}
                  setGlobalState={setGlobalState}
                  notify={notify}
                  modal={modal}
                  closeModal={closeModal}
                />
              }
            >
              <Route index element={<Dashboard />} />
              <Route path="family/:id" element={<Family />} />
              <Route path="beads" element={<Beads />} />
              <Route path="binary-images" element={<BinaryImages />} />
              <Route path="product/:familyid/:id" element={<Product />} />
              <Route path="media" element={<Media />} />
              <Route path="orders" element={<Orders />} />
              <Route path="ekonomi" element={<Ekonomi />} />
            </Route>
            <Route path="*" element={<div>404</div>} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </StyledApp>
  );
};

export default App;

import { useEffect, useState } from "react";
import styled from "styled-components";

const StyledFileForm = styled.div`
  color: white;
`;

const FileForm = ({ handleSubmit, data, header }) => {
  const initialFile = {
    name: "",
  };

  const [formData, setFormData] = useState(initialFile);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (data) setFormData(data);
  }, [data]);

  return (
    <StyledFileForm>
      {header && <h3>{header}</h3>}
      <form
        onSubmit={(e) => {
          setLoading(true);
          e.preventDefault();
          handleSubmit(
            formData,
            () => {
              setFormData(initialFile);
              setLoading(false);
            },
            () => {
              setLoading(false);
            }
          );
        }}
      >
        <input
          type="text"
          value={formData.name}
          onChange={handleChange}
          name="name"
          placeholder="Filnamn"
          required
          disabled={loading}
        />

        <button type="submit" disabled={loading}>
          {loading ? "Sparar..." : "Spara"}
        </button>
      </form>
    </StyledFileForm>
  );
};

export default FileForm;

import styled from "styled-components";
import { DOServer } from "../../utils/config";

const StyledMediaThumbnail = styled.div`
  img {
    height: 100px;
    width: 100px;
    object-fit: contain;
  }
  img.icon {
    border: 1px solid black;
    padding: 30px;
    border-radius: 5px;
  }
`;

const MediaThumbnail = ({ file, onClick }) => {
  if (!file) return null;
  return (
    <StyledMediaThumbnail onClick={onClick}>
      {file.type === "image" && (
        <img
          src={`${DOServer.replace("api/", "")}${file.uuid}/100.${
            file.extension
          }`}
          alt={file.name}
        />
      )}
      {file.type === "video" && (
        <img className="icon" src="/icons/video.svg" alt="video" />
      )}
    </StyledMediaThumbnail>
  );
};

export default MediaThumbnail;

import { Link } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../utils/config";
import ResponsiveImage from "../ui/ResponsiveImage";

const StyledFamilyCard = styled.div`
  background: ${colors.white};
  border-radius: 8px;
  .image-container {
    max-height: 200px;
    overflow: hidden;
    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }
  }
  .text-container {
    position: relative;
    padding: 1rem;
    padding-right: 2rem;
    .family-card-dropdown {
      position: absolute;
      top: 0;
      right: 5px;
      z-index: 20;
    }
  }
`;

const FamilyCard = ({ family, actions }) => {
  return (
    <Link to={`/family/${family._id}`}>
      <StyledFamilyCard>
        <div className="image-container">
          <ResponsiveImage file={family.products?.[0]?.images?.[0]} />
        </div>
        <div className="text-container">
          <p className="m0">{family.name}</p>
        </div>
      </StyledFamilyCard>
    </Link>
  );
};

export default FamilyCard;

import { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../utils/config";

const StyledOrderCard = styled.div`
  /* background: white; */
  margin-bottom: 0.5rem;
  .order-headers {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${colors.white};
    padding: 1rem;
    border-radius: 4px;
    background: ${({ status }) => {
      switch (status) {
        case "Redo att tillverkas":
          return colors.orangeSoda;
        case "Redo att skickas":
          return colors.sandyBrown;
        case "Skickad":
          return colors.darkPastelGreen;

        case "Utbetald":
          return colors.darkBlue;
        default:
          return "red";
      }
    }};
  }
  .order-full {
    max-height: 0;
    padding: 1rem;
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
    transition: 200ms ease-in-out;
    overflow: hidden;
    background: white;
    .products,
    .letterinfo,
    .customerinfo,
    .comment {
      background: rgba(0, 20, 200, 0.1);
      border-radius: 4px;
      margin-bottom: 1rem;
      .prod {
        margin-bottom: 1rem;
        position: relative;
        &:after {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 1px;
          background: ${colors.azure};
          content: "";
        }
        p {
          margin: 0;
        }
      }
    }
  }

  &.open {
    margin-bottom: 2rem;
    .order-full {
      .products,
      .letterinfo,
      .customerinfo,
      .comment {
        padding: 0.5rem;
        .prod {
          padding-bottom: 0.5rem;
        }
      }
      padding: 1rem;
      border-radius: 0 0 4px 4px;
      max-height: 100vh;
      overflow: scroll;
    }
  }
`;

const OrderCard = ({ order, markAsSent, deleteOrder, markAsFinished }) => {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    setIsOpen(false);
    //eslint-disable-next-line
  }, []);

  const getStatus = () => {
    const dict = {
      readyToShip: "Redo att tillverkas",
      shipped: "Skickad",
      paidToAccount: "Utbetald",
    };
    let stat = dict[order.payson.status] || "Tok";
    if (order.isFinished) {
      if (stat === "Skickad" || stat === "Utbetald") return stat;
      stat = "Redo att skickas";
    }
    return stat;
  };

  const parseDate = (d) => new Date(d).toLocaleString("sv");

  const getTotalQuant = () =>
    order.products
      .map((prod) => prod.quantity)
      .reduce((acc, curr) => acc + curr);

  return (
    <StyledOrderCard className={`${isOpen ? "open" : ""}`} status={getStatus()}>
      <div className="order-headers">
        <div className="order-headers-left">
          <p className="m0">
            {order.payson.customer.firstName} {order.payson.customer.lastName}
          </p>
          <p className="m0">
            Order {order.orderNumber}, skapad {parseDate(order.createdAt)}
          </p>
          <p className="m0 mbottom1">
            {getTotalQuant()} produkter,{" "}
            {order.products
              .map(
                (prod) =>
                  prod.quantity * prod.product?.priceSEK || prod.quantity * 130
              )
              .reduce((acc, curr) => acc + curr)}
            kr
          </p>
          <button onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? "Minimera" : "Expandera"}
          </button>
        </div>
        <div className="order-headers-right">
          <p className="m0">{getStatus()}</p>
          {getStatus() === "Redo att tillverkas" ? (
            <button onClick={() => markAsFinished(order)}>
              Markera som färdig
            </button>
          ) : null}
          {getStatus() === "Redo att skickas" ? (
            <button onClick={() => markAsSent(order)}>
              Markera som skickad
            </button>
          ) : null}
        </div>
      </div>
      <div className="order-full">
        <div className="comment">
          <h4 className="m0">Kommentar</h4>
          <p className="m0">{order.comment}</p>
        </div>
        <div className="products">
          <h4 className="m0 mbottom1">Produkter</h4>

          {order.products.map((prod) => {
            if (!prod.product) {
              return (
                <div className="prod" key={prod._id}>
                  <p>Namn: Binärband</p>
                  <p>Antal: {prod.quantity}</p>
                  <p>Grundfärg: {prod.details.baseColor.name}</p>
                  <p>1-färg: {prod.details.oneColor.name}</p>
                  <p>0-färg: {prod.details.zeroColor.name}</p>
                  <p>Datum: {prod.details.dateDec}</p>
                  <p>Binärkod: {prod.details.dateBin}</p>
                </div>
              );
            } else
              return (
                <div className="prod" key={prod._id}>
                  <p>Namn: {prod.product.name}</p>
                  <p>Antal: {prod.quantity}</p>
                </div>
              );
          })}
        </div>
        <div className="letterinfo">
          <h4 className="m0 mbottom1">Ska stå på brevet</h4>
          <p>
            {order.payson.customer.firstName} {order.payson.customer.lastName}
          </p>
          <p>{order.payson.customer.street}</p>
          <p>{order.payson.customer.postalCode}</p>
          <p>{order.payson.customer.city}</p>
        </div>
        <div className="customerinfo">
          <h4 className="m0">Kundinfo</h4>

          <p>Landskod: {order.payson?.customer.countryCode}</p>
          <p>Typ: {order.payson?.customer.type}</p>
          <p>Personnummer: {order.payson?.customer.identityNumber}</p>
          <p>Mail: {order.payson?.customer.email}</p>
          <p>Telefon: {order.payson?.customer.phone}</p>
        </div>
      </div>
    </StyledOrderCard>
  );
};

export default OrderCard;

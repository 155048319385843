import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import styled from "styled-components";
import { fetcher } from "../../utils/fetcher";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { colors } from "../../utils/config";

const StyledEkonomi = styled.div`
  .search-params {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
    label {
      display: block;
    }
  }
`;

const Ekonomi = () => {
  const { globalState, notify } = useOutletContext();

  const [searchParameters, setSearchParameters] = useState({
    startDate: new Date("2022-06-13").toLocaleDateString("sv"),
    endDate: new Date().toLocaleDateString("sv"),
  });
  // const [expenses, setExpenses] = useState([]);
  const [result, setResult] = useState();
  const [totals, setTotals] = useState();
  const [perProduct, setPerProduct] = useState();
  const [loading, setLoading] = useState(true);

  const handleChange = (e) => {
    setSearchParameters({
      ...searchParameters,
      [e.target.name]: e.target.value,
    });
  };

  const getData = async () => {
    setLoading(true);
    const { err, data } = await fetcher({
      path: "economy",
      auth: globalState.token,
      method: "POST",
      body: searchParameters,
    });
    if (err) {
      setLoading(false);
      notify.error("Kunde inte hämta data");
      return;
    }
    // setExpenses(data.expenses);
    setResult(data.data);
    setTotals(data.totals);
    setPerProduct(data.perProduct);
    setLoading(false);
  };

  useEffect(() => {
    getData();
    //eslint-disable-next-line
  }, [searchParameters]);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Inkomster per dag",
      },
    },
  };

  if (loading) return <p>Laddar...</p>;
  return (
    <StyledEkonomi className="content">
      <h1 className="m0 mbottom1 mtop1">Ekonomi</h1>
      <div className="search-params">
        <div className="input-wrapper">
          <label htmlFor="startDate">Startdatum</label>
          <input
            type="date"
            onChange={handleChange}
            value={searchParameters.startDate}
            name="startDate"
            id="startDate"
          />
        </div>
        <div className="input-wrapper">
          <label htmlFor="endDate">Slutdatum</label>
          <input
            type="date"
            onChange={handleChange}
            value={searchParameters.endDate}
            name="endDate"
            id="endDate"
          />
        </div>
      </div>
      <div className="statistics">
        <h4 className="m0">Total inkomst ex moms</h4>
        <p className="m0 mbottom2">{totals.incomeExVat}</p>
        <Bar
          options={options}
          data={{
            labels: result.map((day) => day.parsedDate),
            datasets: [
              {
                label: "Inkomster ex moms",
                data: result.map((day) => day.sumExVat),
                backgroundColor: colors.lightBlue,
              },
            ],
          }}
        />
        <Bar
          options={{
            ...options,
            plugins: {
              ...options.plugins,
              title: { ...options.plugins.title, text: "Inkomst per produkt" },
            },
          }}
          data={{
            labels: perProduct.map((p) => p.name),
            datasets: [
              {
                label: "Inkomster ink moms",
                data: perProduct.map((p) => p.price),
                backgroundColor: colors.azure,
              },
            ],
          }}
        />
        <Bar
          options={{
            ...options,
            plugins: {
              ...options.plugins,
              title: {
                ...options.plugins.title,
                text: "Antal sålda per produkt",
              },
            },
          }}
          data={{
            labels: perProduct.map((p) => p.name),
            datasets: [
              {
                label: "Antal sålda",
                data: perProduct.map((p) => p.quantity),
                backgroundColor: colors.darkBlue,
              },
            ],
          }}
        />
      </div>
    </StyledEkonomi>
  );
};

export default Ekonomi;

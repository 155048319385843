import { Link } from "react-router-dom";
import styled from "styled-components";
import { measurments } from "../../utils/config";

const StyledNavigation = styled.div`
  background: lightgray;
  .nav-inner {
    height: ${measurments.navHeight}px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .nav-right {
      a,
      button {
        margin-left: 0.5rem;
      }
    }
  }
`;

const Navigation = ({ globalState, setGlobalState, notify }) => {
  const logout = () => {
    localStorage.removeItem("hannahdesign-admin-token");
    setGlobalState({
      ...globalState,
      user: null,
      token: null,
    });
    notify.success("Välkommen åter!");
  };

  return (
    <StyledNavigation>
      {globalState.user && (
        <div className="nav-inner content">
          <div className="nav-left">
            <p className="m0">Välkommen {globalState.user.username}!</p>
          </div>
          <div className="nav-right">
            <Link to="/">Produkter</Link>
            <Link to="/beads">Pärlor</Link>
            <Link to="/binary-images">Binärbilder</Link>
            <Link to="/media">Media</Link>
            <Link to="/orders">Ordrar</Link>
            <Link to="/ekonomi">Ekonomi</Link>
            <button onClick={logout}>Logga ut</button>
          </div>
        </div>
      )}
    </StyledNavigation>
  );
};

export default Navigation;
